<template>
    <v-container>
    <v-row>
      <v-col>
        <!--Header-->
        <v-card-title>
          <v-btn @click="$router.go(-1)" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h3>Stock Entries</h3>
          
          <!--Button-->
          <v-btn
            elevation="2"
            color="#33cc99"
            dark
            class="ml-10" 
            @click="$router.push('/new-stock-entry')"
            >
            <v-icon left>mdi-plus</v-icon>
            New Stock Entry
        </v-btn>
        </v-card-title>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
          <v-sheet elevation="4">
            <!--Stock Entry data table-->
            <template>
              <div>
                <v-data-table
                    :headers="entryTableHeaders"
                    :items="filteredItems"
                    :search="search"
                    show-expand
                    single-expand
                    item-key="entry_id"
                >

                  <!--Line item expansion dropdown-->
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="px-0">
                        <v-sheet elevation="0" class="py-3">
                          <v-container>
                                <v-row>
                                    <v-col md="3" sm="3">
                                        <h4>Item name</h4>
                                    </v-col>
                                    <v-col md="3" sm="3">
                                        <h4>Variant</h4>
                                    </v-col>
                                    
                                    <v-col md="3" sm="3">
                                            <h4>Adjustment Type</h4>
                                    </v-col>
                                    <v-col md="3" sm="3">
                                            <h4>QTY</h4>
                                    </v-col>
                                </v-row>
                                <div
                                    v-for="(e, index) in item.entry_items"
                                    :key="e.item_id"
                                >
                                <v-row class="py-2">
                                    <v-col md="3" sm="3">
                                        {{e.item_name}}
                                    </v-col>
                                    <v-col md="3" sm="3">
                                        {{ e.selectedVariant ? formatProductVariant(e.selectedVariant) : "" }}
                                    </v-col>
                                    <v-col md="3" sm="3">
                                        {{e.transform}}
                                    </v-col>
                                    <v-col md="3" sm="3">
                                        {{e.qty}}
                                    </v-col>
                                    <v-col md="3" sm="3">
                                        
                                    </v-col>
                                </v-row>
                                <v-divider
                                    v-if="index < item.entry_items.length -1"
                                    :key="index"
                                ></v-divider>
                                </div>
                          </v-container>

                        </v-sheet>
                    </td>
                  </template>
                  <!-- Action items -->
                  <template v-slot:item.actions="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="generateStockEntryPDF(item)">
                                <v-list-item-title>Download PDF</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="generateStockEntryItemLabelsA4PDF(item)">
                                <v-list-item-title>Download A4 Item Labels</v-list-item-title>
                            </v-list-item>
                            <!-- Add more actions here -->
                        </v-list>
                    </v-menu>
                </template>
                  
                </v-data-table>
              </div>
            </template>
          </v-sheet>
        </v-col>
      </v-row>
  </v-container>
</template>
<script>
import db from "../../../components/firebaseInit";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import { generateStockEntryPDF, generateStockEntryItemLabelsA4PDF } from "../data/stockEntryPDFs";
export default {
    name: "StockEntries",
    mixins: [mixin_UserRoles],
    data(){
        return {
            search: "",
            stock_entries: [],
            entryTableHeaders: [
              { text: 'Entry Number', sortable: true, value: 'entry_number'},
              { text: 'Branch', sortable: true, value: 'entry_branch_name'},
              { text: 'Date', sortable: true, value: 'entry_date'},
              { text: 'Actions', sortable: true, value: 'actions' },
            ],
        }
    },
    computed: {
        filteredItems() {
            return this.stock_entries;
        },
    },
    methods: {
      generateStockEntryPDF,
      generateStockEntryItemLabelsA4PDF,
      getStockEntries() {
        const docRef = db.collection("stock_entries").orderBy("entry_number");
        docRef.onSnapshot(snapshot => this.stock_entries = snapshot.docs.map(x => x.data()));
      },
      //
      formatProductVariant(variant) {
            const { variant_set_name, variant_name, variant_code } = variant;
            return `${variant_set_name} - ${variant_name} (${variant_code})`;
        }
    },
    created(){
      this.getStockEntries();
    }
}
</script>
