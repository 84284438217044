<template>
    <v-container  fluid>
        <v-row dense>
            <v-col lg="8" offset-lg="2">
                <v-card-title>
                    <v-btn
                        to="/stock-entries"
                        color="#ffffff"
                        light
                        elevation="0"
                    >
                    <v-icon left large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h3>New Stock Entry</h3>
                </v-card-title>
                <v-card>
                    <v-form @submit.prevent="submitStockEntry" class="pa-3" ref="form" v-model="valid">
                        <v-container>
                            <v-row dense>
                                <!-- Entry Number -->
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="entry_number"
                                        label="Entry #"
                                        readonly
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>

                                <!-- Entry Date -->
                                <!--Date Picker-->
                                <v-col cols="12" sm="6" md="6">
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="true"
                                        :return-value.sync="date"
                                        transition="scale-transition"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="entry_date"
                                            label="Entry Date"
                                            append-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            dense
                                            outlined
                                            clearable
                                            color="#3d2cdd"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="entry_date"
                                        :rules="[rules.required]"
                                        no-title
                                        scrollable
                                        color="#3d2cdd"
                                        show-adjacent-months
                                        :allowed-dates="allowedDates"
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="#ce2458" @click="menu = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="#33cc99"
                                            @click="$refs.menu.save(date)"
                                        >
                                            OK
                                        </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" sm="6" md="6">
                                    <v-autocomplete
                                        v-model="entry_branch"
                                        :items="branches"
                                        item-text="branch_name"
                                        item-value="branch_id"
                                        :rules="[rules.required]"
                                        cache-items
                                        label="Branch"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                        class="font-weight-bold"
                                        :menu-props="{ closeOnClick: true }"
                                    >
                                        <!--Format list items-->
                                        <template v-slot:selection="data" trim>
                                        {{ data.item.branch_name }}
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <!-- Line items -->
                            <hr>
                                <v-form v-for="(item, index) in entry_items" :key="item.item_id" class="mt-5">
                                    <v-row dense>
                                        <v-col 
                                            :sm="item.variants && item.variants.length > 0 ? '3' : '5'"
                                            :md="item.variants && item.variants.length > 0 ? '3' : '5'"
                                            >
                                            <v-autocomplete
                                                v-model="item.data"
                                                :items="items"
                                                item-text="item_name"
                                                :return-object="true"
                                                :rules="[rules.required]"
                                                placeholder="Choose an item..."
                                                cache-items
                                                required
                                                outlined
                                                dense
                                                color="#3d2cdd"
                                                @change="setItemData(item.data, index)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <!-- Variants -->
                                        <v-col  sm="3" md="3" v-if="item.hasVariants">
                                            <v-autocomplete
                                                v-model="item.selectedVariant"
                                                :items="item.variantList"
                                                item-text="variant_name"
                                                return-object
                                                :rules="[rules.required]"
                                                cache-items
                                                :label="item.variant ? item.variant.variant_set_name : 'Variant'"
                                                outlined
                                                dense
                                                color="#3d2cdd"
                                                class="font-weight-bold"
                                                :menu-props="{ closeOnClick: true }"
                                            >
                                                <!-- Dropdown items -->
                                                <template v-slot:item="data">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ `${data.item.variant_set_name} - ${data.item.variant_name}` }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <!-- Transform type -->
                                        <v-col cols="12" sm="2" md="2">
                                            <v-select
                                                v-model="item.transform"
                                                :items="transformTypes"
                                                :rules="[rules.required]"
                                                outlined
                                                label="Adjustment"
                                                dense
                                                color="#3d2cdd"
                                            >
                                            </v-select>
                                        </v-col>
                                        <!-- Quantity -->
                                        <v-col cols="12" sm="1" md="1">
                                            <v-text-field
                                                v-model.number="item.qty"
                                                type="number"
                                                label="Quantity"
                                                :rules="[rules.required]"
                                                outlined
                                                dense
                                                color="#3d2cdd"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <!-- Delete Button -->
                                        <v-col cols="12" sm="1" md="1">
                                            <v-btn
                                                v-if="index !== 0"
                                                small
                                                icon
                                                color="red"
                                                @click="deleteEntryItem(index)"
                                            >
                                            <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                <!-- Add item button -->
                                <v-row dense>
                                    <v-col>
                                    <v-btn @click="addEntryItem" color="#33cc99" dark>
                                        <v-icon left>mdi-plus</v-icon>
                                        Add Item
                                    </v-btn>
                                    </v-col>
                                </v-row>
                        </v-container>
                    </v-form>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            to='/branch-stock-transfers'
                            color="#ce2458"
                            text
                            x-large
                        >
                            Cancel
                            <v-icon right>mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                            @click="submitStockEntry"
                            color="#33cc99"
                            text
                            x-large
                            :disabled="valid === false"
                            :loading="loading"
                        >
                            Submit
                            <v-icon right>mdi-check</v-icon>
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import db from '../../../components/firebaseInit'
import {showSnackbar} from '../../../globalActions/index'
//Mixins
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'

export default {
    name: "StockEntryForm",
    mixins: [ mixin_UserRoles ],
    data(){
        return {
            valid: false,
            loading: false,
            menu: false,
            date: null,
            entry_number: null,
            entry_date: new Date().toISOString().substr(0, 10), // Default to today's date
            entry_branch: "vs8s17IYEk94LC2gYhRU", // Montague Gardens
            entry_items: [
                {
                item_id: null,
                item_name: "",
                transform: "Increase",
                qty: 0,
                variants: [],
                hasVariants: false,
                },
            ],
            branches: [],
            items: [],
            transformTypes: ["Increase", "Decrease"],
            rules: {
            required: value => !!value || 'Required.',
        },
        }
    },
    methods: {
        async submitStockEntry() {
            // Form validation
            if (!this.$refs.form.validate()) {
                return;
            }

            this.loading = true;

            try {
                await db.runTransaction(async (transaction) => {
                    const counterRef = db.collection('stock_entry_counter').doc('T3dxxDjUOAQsHa5Uhb4C');
                    const counterDoc = await transaction.get(counterRef);

                    if (!counterDoc.exists) {
                        throw new Error('Counter document does not exist!');
                    }

                    // Increment entry counter
                    const newCount = counterDoc.data().entry_count + 1;

                    // Prepare stock entry data
                    const stockEntryRef = db.collection('stock_entries').doc();
                    const selectedBranch = this.branches.find(branch => branch.branch_id === this.entry_branch);
                    const branchName = selectedBranch ? selectedBranch.branch_name : '';

                    const stockEntryData = {
                        entry_id: stockEntryRef.id,
                        entry_number: `STE-${String(newCount).padStart(4, '0')}`,
                        entry_date: this.entry_date,
                        entry_branch_id: this.entry_branch,
                        entry_branch_name: branchName,
                        entry_items: this.entry_items.map(item => ({
                            item_id: item.item_id,
                            item_name: item.item_name,
                            qty: item.qty,
                            transform: item.transform,
                            selectedVariant: item.selectedVariant
                        }))
                    };

                    // Group items by item_id
                    const groupedItems = {};
                    for (const item of this.entry_items) {
                        const key = item.item_id;
                        if (!groupedItems[key]) {
                            groupedItems[key] = { item, totalQty: 0, variants: {} };
                        }
                        groupedItems[key].totalQty += item.qty;
                        if (item.hasVariants) {
                            const variantKey = `${item.selectedVariant.variant_set_id}-${item.selectedVariant.variant_name}`;
                            if (!groupedItems[key].variants[variantKey]) {
                                groupedItems[key].variants[variantKey] = 0;
                            }
                            groupedItems[key].variants[variantKey] += item.qty;
                        }
                    }

                    // Read and prepare updates for each grouped item
                    const updates = [];
                    for (const key in groupedItems) {
                        const { item, totalQty, variants } = groupedItems[key];
                        const stockDocRef = db.collection('branches')
                            .doc(this.entry_branch)
                            .collection('stock_levels')
                            .doc(item.item_id);
                        const stockDoc = await transaction.get(stockDocRef);

                        if (stockDoc.exists) {
                            const stockData = stockDoc.data();
                            if (item.hasVariants) {
                                // Update each variant's stock_current
                                for (const variantKey in variants) {
                                    const [variant_set_id, variant_name] = variantKey.split('-');
                                    const variantIndex = stockData.variants.findIndex(
                                        variant => variant.variant_name === variant_name &&
                                            variant.variant_set_id === variant_set_id
                                    );

                                    if (variantIndex !== -1) {
                                        stockData.variants[variantIndex].stock_current += (item.transform === 'Increase' ? variants[variantKey] : -variants[variantKey]);
                                    }
                                }
                            } else {
                                // Update the item's stock_current
                                stockData.stock_current += (item.transform === 'Increase' ? totalQty : -totalQty);
                            }

                            // Add the update operation to the updates array
                            updates.push({ ref: stockDocRef, data: stockData });
                        }
                    }

                    // Perform all writes after all reads
                    updates.forEach(update => {
                        transaction.set(update.ref, update.data);
                    });
                    transaction.update(counterRef, { entry_count: newCount });
                    transaction.set(stockEntryRef, stockEntryData);
                });

                this.$router.push('/stock-entries');
                showSnackbar("New stock entry added successfully");
            } catch (error) {
                console.error('Error creating stock entry:', error);
            } finally {
                this.loading = false;
            }
        },
        //
        addEntryItem() {
            this.entry_items.push({
                item_id: null,
                item_name: "",
                transform: "Increase",
                qty: 0,
                variants: [],
                hasVariants: false,
            },);
        },
        //
        deleteEntryItem(index) {
            this.entry_items.splice(index,1);
        },
        //
        getEntryCount() {
            this.loading = true;

            const docRef = db.collection("stock_entry_counter").doc("T3dxxDjUOAQsHa5Uhb4C");
            
            docRef.onSnapshot((snapshot) => {
                const { entry_count } = snapshot.data();
                // Format branch_count with leading zeros
                const formattedCount = `${entry_count + 1}`.padStart(4, '0');
                this.entry_number = `STE-${formattedCount}`;
                this.loading = false;
            });
        },
        // Get branches
        getBranches() {
            const docRef = db.collection("branches").orderBy("branch_name");

            docRef.onSnapshot((snapshot) => {
                this.branches = [];
                this.branches = snapshot.docs.map(doc => doc.data());
            });
        },
        // Allowed scheduled dates
        allowedDates(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const selectedDate = new Date(date);
            return selectedDate > today;
        },
        //
        async getItems() {
            const docRef = db.collection('branches')
                .doc(this.entry_branch).collection('stock_levels').orderBy('item_name');

            const snapshot = await docRef.get();
            this.items = snapshot.docs.map(x => x.data());
        },
        //
        setItemData(selectedItem, index) {
            // Reset variant data
            this.$set(this.entry_items[index], 'hasVariants', false);
            this.$set(this.entry_items[index], 'selectedVariant', null);

            if (selectedItem) {
                const { item_id, item_name, variants } = selectedItem;
                // Set product data
                this.$set(this.entry_items[index], 'item_id', item_id);
                this.$set(this.entry_items[index], 'item_name', item_name);

                // Check for variants
                if (variants && variants.length > 0) {
                    this.$set(this.entry_items[index], 'hasVariants', true);
                    this.$set(this.entry_items[index], 'variantList', variants);
                }
            }
        },
    },
    created() {
        this.getEntryCount();
        this.getBranches();
    },
    mounted() {
        this.getItems();
    }

}
</script>
