import jsPDF from "jspdf";
import "jspdf-autotable";

export const generateBranchStockTransferPDF = (transferDetails) => {
    const {
        entry_items,
        transfer_number,
        from_branch_name,
        to_branch_name,
        date_scheduled,
    } = transferDetails;

    const doc = new jsPDF();
    const detailRows = [
        ["Transfer #", transfer_number],
        ["From Branch", from_branch_name],
        ["To Branch", to_branch_name],
        ["Date Scheduled", date_scheduled],
    ];
    const itemTableColumn = ["Item Name", "Qty Sent", "Variant", "Qty Received"];
    const itemTableRows = [];

    const formatVariantName = (variant) => {
        return variant ? `${variant.variant_set_name} - ${variant.variant_name}` : "-";
    };

    entry_items.forEach(item => {
        const itemData = [
            item.item_name,
            item.qty,
            formatVariantName(item.selectedVariant),
            item.qty_received || ""
        ];
        itemTableRows.push(itemData);
    });

    doc.setFontSize(16);
    doc.setFont(undefined, 'bold');
    doc.text("BRANCH STOCK TRANSFER", 105, 20, null, null, 'center');

    doc.autoTable({
        body: detailRows,
        startY: 30,
        theme: 'plain',
        styles: { fontSize: 9, cellPadding: 2 },
        columnStyles: { 0: { fontStyle: 'bold' } }, // Make the first column bold
        tableWidth: 'auto',
        margin: { right: 105 } // Adjust the right margin to make the table half-width
    });

    doc.autoTable({
        head: [itemTableColumn],
        body: itemTableRows,
        startY: doc.lastAutoTable.finalY + 10,
        theme: 'grid',
        headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] }, // Set header background to white and text to black
        styles: { fontSize: 9, cellPadding: 2 }
    });

    const lastY = doc.lastAutoTable.finalY + 20;
    const receiptRows = [
        ["Items Received by", ""],
        ["Signature", ""],
        ["Date", ""]
    ];
    doc.autoTable({
        body: receiptRows,
        startY: lastY,
        theme: 'grid',
        columnStyles: {
            0: { fontStyle: 'bold', cellWidth: 92 },
            1: { cellWidth: 92 }
        },
        styles: { fontSize: 9, cellPadding: 2 },
        tableWidth: 'auto',
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.setFont(undefined, 'normal');
        doc.text(`Page ${i} of ${pageCount}`, 105, 285, null, null, 'center');
    }

    doc.save(`${transfer_number}.pdf`);
};

// 
export const generateStockItemLabelsA4PDF = (transferDetails) => {
    const {
        entry_items,
        transfer_number,
        to_branch_name,
        date_scheduled,
    } = transferDetails;

    const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
    });

    entry_items.forEach((item, index) => {
        for (let qtyIndex = 1; qtyIndex <= item.qty; qtyIndex++) {
            const variantText = item.selectedVariant ? `${item.selectedVariant.variant_set_name} - ${item.selectedVariant.variant_name}` : "N/A";
            const labelData = [
                ["Transfer:", transfer_number],
                ["Item:", item.item_name],
                ["Variant:", variantText],
                ["To Branch:", to_branch_name],
                ["Date:", date_scheduled],
                ["Quantity:", `${qtyIndex} of ${item.qty}`]
            ];

            doc.autoTable({
                body: labelData,
                theme: 'plain',
                styles: { fontSize: 22, cellPadding: 4 }, // Adjust font size and padding for larger labels
                columnStyles: { 0: { fontStyle: 'bold' } },
                margin: { top: 25, left: 20, right: 20 }, // Adjust margins for A4 format
                tableWidth: 'wrap', // Adjust table width to fit content
                showHead: false,
                showFoot: false,
            });

            if (!(qtyIndex === item.qty && index === entry_items.length - 1)) {
                doc.addPage("a4", "landscape");
            }
        }
    });

    doc.save(`A4_Labels_${transfer_number}.pdf`);
};


export const generateStockItemLabelsNimbotPDF = (transferDetails) => {
    const {
        entry_items,
        transfer_number,
        to_branch_name,
        date_scheduled,
    } = transferDetails;

    const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [70, 50],
    });

    entry_items.forEach((item, index) => {
        for (let qtyIndex = 1; qtyIndex <= item.qty; qtyIndex++) {
            const variantText = item.selectedVariant ? `${item.selectedVariant.variant_set_name} - ${item.selectedVariant.variant_name}` : "N/A";
            const labelData = [
                ["Transfer:", transfer_number],
                ["Item:", item.item_name],
                ["Variant:", variantText],
                ["To Branch:", to_branch_name],
                ["Date:", date_scheduled],
                ["Quantity:", `${qtyIndex} of ${item.qty}`]
            ];

            doc.autoTable({
                body: labelData,
                theme: 'plain',
                styles: { fontSize: 8, cellPadding: 0.5 },
                columnStyles: { 0: { fontStyle: 'bold' } },
                margin: { top: 10, left: 5, right: 5 },
                tableWidth: 60,
                showHead: false,
                showFoot: false,
            });

            if (!(qtyIndex === item.qty && index === entry_items.length - 1)) {
                doc.addPage([70, 50]);
            }
        }
    });

    doc.save(`Labels_${transfer_number}.pdf`);
};

