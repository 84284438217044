<template>
    <v-container>
      <v-row>
        <v-col>
          <!--Header-->
          <v-card-title>
            <v-btn @click="$router.go(-1)" color="#ffffff" light elevation="0">
              <v-icon left large>mdi-chevron-left</v-icon>
            </v-btn>
            <h3>Inventory</h3>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet elevation="4">
            <v-data-table
              :headers="tableHeaders"
              :items="filteredItems"
              :search="search"
              :items-per-page="500"
              item-key="item_id"
              show-expand
              :item-class="itemClass"
            >
                <!-- Filters -->
                <template v-slot:top>
                    <v-container fluid>
                        <v-row> 
                            <v-col lg="3" md="3">
                                <v-autocomplete
                                    :items="branches"
                                    item-text="branch_name"
                                    item-value="branch_id"
                                    v-model='selectedBranch'
                                    label="Branch"
                                    outlined
                                    flat
                                    solo
                                    dense
                                    append-icon="mdi-filter-variant"
                                    @change="getStockLevels(selectedBranch)"
                                >
                              <template>
                                <v-list-item slot="prepend-item">
                                    <h5>Filter by Branch</h5>
                                </v-list-item>
                            </template>
                                </v-autocomplete> 
                        </v-col> 
                        <v-spacer />
                              <!--Search bar filter -->
                              <v-col lg="4" md="4">
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                rounded
                                dense
                                filled
                                clearable
                                ></v-text-field>
                              </v-col> 
                            </v-row>
                          </v-container>
                      </template>
                <!-- Variant expansion panel -->
                <template v-slot:expanded-item="{ item }">
                    <td :colspan="tableHeaders.length + 1">
                        <v-data-table
                            :headers="variantTableHeaders"
                            :items="item.variants"
                            :items-per-page="500"
                            :item-class="itemClass"
                            >
                            <template v-slot:item.variant_name="{ item }">
                                {{ item.variant_set_name }} - {{ item.variant_name }} ({{ item.variant_code }})
                            </template>
                        </v-data-table>
                    </td>
                </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import db from "../../../components/firebaseInit";
  import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
  
  export default {
    name: "InventoryDashboard",
    mixins: [mixin_UserRoles],
    data() {
      return {
        search: "",
        items: [],
        branches: [],
        selectedBranch: 'vs8s17IYEk94LC2gYhRU', // Default to Monague Gardens factory
        stock_levels: [],
        tableHeaders: [
          { text: "Item", sortable: true, value: "item_name" },
          { text: "Stock Available", sortable: true, value: "stock_available" },
          { text: "Current Stock", sortable: true, value: "stock_current" },
          { text: "Stock Required", sortable: true, value: "stock_required" },
          { text: "Stock Scheduled", sortable: true, value: "stock_scheduled" },
          { text: "Stock Allocated", sortable: true, value: "stock_allocated" },
        ],
        variantTableHeaders: [
          { text: "Variant", sortable: true, value: "variant_name" },
          { text: "Stock Available", sortable: true, value: "stock_available" },
          { text: "Current Stock", sortable: true, value: "stock_current" },
          { text: "Stock Required", sortable: true, value: "stock_required" },
          { text: "Stock Scheduled", sortable: true, value: "stock_scheduled" },
          { text: "Stock Allocated", sortable: true, value: "stock_allocated" },
        ],
        stockLevelsUnsubscribe: null,
      };
    },
    computed: {
        filteredItems() {
            return this.stock_levels.filter(x => x.branch_id === this.selectedBranch);
        }
    },
    beforeDestroy() {
      if (this.stockLevelsUnsubscribe) {
          this.stockLevelsUnsubscribe();
      }
  },
    methods: {
      async getProducts() {
        const docRef = db.collection("products");
        const snapshot = await docRef.get();
        const productsList = snapshot.docs.map((doc) => doc.data());
  
        const items = [];
  
        for (const { product_id, product_name, product_variants } of productsList) {
          let item = {
            item_id: product_id,
            item_name: product_name,
          };
  
          if (product_variants && product_variants.length > 0) {
            const variants = await this.getVariantsFromProduct(product_variants);
            item.variants = variants;

          } else {

            item = {
                ...item,
                stock_required: 0,
                stock_scheduled: 0,
                stock_current: 0,
                stock_allocated: 0,
                stock_available: 0
            }
          }
  
          items.push(item);
        }
  
        this.items = items;
      },
      async getVariantsFromProduct(product_variants) {
        const variants = [];
  
        for (const { variant_set_id, variant_set_name } of product_variants) {
          const docRef = db.collection("variant_sets").doc(variant_set_id);
          const docSnapshot = await docRef.get();
          if (docSnapshot.exists) {
            const variantSetData = docSnapshot.data();
            const variantsWithSetName = variantSetData.variants.map((variant) => ({
                ...variant,
                variant_set_name: variant_set_name,
                variant_set_id: variant_set_id,
                stock_required: 0,
                stock_scheduled: 0,
                stock_current: 0,
                stock_allocated: 0,
                stock_available: 0
            }));
            variants.push(...variantsWithSetName);
          }
        }
        return variants;
      },
      async getBranches() {
        const docRef = db.collection('branches');
        const snapshot = await docRef.get();
        this.branches = snapshot.docs.map(x => {
            const { branch_name, branch_id } = x.data();
            return {branch_name, branch_id  }
        });
      },
        //
        getStockLevels(branch_id) {
            // Unsubscribe from any previous snapshot listeners
            if (this.stockLevelsUnsubscribe) {
                this.stockLevelsUnsubscribe();
            }

            const docRef = db.collection('branches').doc(branch_id).collection('stock_levels').orderBy('item_name');

            // Subscribe to real-time updates
            this.stockLevelsUnsubscribe = docRef.onSnapshot(snapshot => {
                const stockLevels = snapshot.docs.map(doc => {
                    const data = doc.data();
                  
                    // Calculate stock_available for each variant
                    if (data.variants) {
                        data.variants = data.variants.map(variant => {
                            variant.stock_available = variant.stock_current - variant.stock_allocated;
                            return variant;
                        });
                    } else {
                      data.stock_available = data.stock_current - data.stock_allocated;
                    }

                    return data;
                });
                this.stock_levels = stockLevels;
            });
        },
        //
        itemClass(item) {
            if (item.stock_available === 0) return 'yellow lighten-4'
            if (item.stock_available > 0) return 'green lighten-4'  
        },
    },
    created() {
      this.getProducts();
      this.getBranches();
      this.getStockLevels(this.selectedBranch);
    },
  };
  </script>
  
  <style scoped>
</style>