var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card-title',[_c('v-btn',{attrs:{"color":"#ffffff","light":"","elevation":"0"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":"","large":""}},[_vm._v("mdi-chevron-left")])],1),_c('h3',[_vm._v("Stock Entries")]),_c('v-btn',{staticClass:"ml-10",attrs:{"elevation":"2","color":"#33cc99","dark":""},on:{"click":function($event){return _vm.$router.push('/new-stock-entry')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New Stock Entry ")],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"4"}},[[_c('div',[_c('v-data-table',{attrs:{"headers":_vm.entryTableHeaders,"items":_vm.filteredItems,"search":_vm.search,"show-expand":"","single-expand":"","item-key":"entry_id"},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('v-sheet',{staticClass:"py-3",attrs:{"elevation":"0"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"3","sm":"3"}},[_c('h4',[_vm._v("Item name")])]),_c('v-col',{attrs:{"md":"3","sm":"3"}},[_c('h4',[_vm._v("Variant")])]),_c('v-col',{attrs:{"md":"3","sm":"3"}},[_c('h4',[_vm._v("Adjustment Type")])]),_c('v-col',{attrs:{"md":"3","sm":"3"}},[_c('h4',[_vm._v("QTY")])])],1),_vm._l((item.entry_items),function(e,index){return _c('div',{key:e.item_id},[_c('v-row',{staticClass:"py-2"},[_c('v-col',{attrs:{"md":"3","sm":"3"}},[_vm._v(" "+_vm._s(e.item_name)+" ")]),_c('v-col',{attrs:{"md":"3","sm":"3"}},[_vm._v(" "+_vm._s(e.selectedVariant ? _vm.formatProductVariant(e.selectedVariant) : "")+" ")]),_c('v-col',{attrs:{"md":"3","sm":"3"}},[_vm._v(" "+_vm._s(e.transform)+" ")]),_c('v-col',{attrs:{"md":"3","sm":"3"}},[_vm._v(" "+_vm._s(e.qty)+" ")]),_c('v-col',{attrs:{"md":"3","sm":"3"}})],1),(index < item.entry_items.length -1)?_c('v-divider',{key:index}):_vm._e()],1)})],2)],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.generateStockEntryPDF(item)}}},[_c('v-list-item-title',[_vm._v("Download PDF")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.generateStockEntryItemLabelsA4PDF(item)}}},[_c('v-list-item-title',[_vm._v("Download A4 Item Labels")])],1)],1)],1)]}}])})],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }