import jsPDF from "jspdf";
import "jspdf-autotable";

export const generateStockEntryPDF = (stockEntryDetails) => {
    const {
        entry_items,
        entry_number,
        entry_branch_name,
        entry_date,
    } = stockEntryDetails;

    const doc = new jsPDF();
    const detailRows = [
        ["Entry #", entry_number],
        ["Branch", entry_branch_name],
        ["Entry Date", entry_date],
    ];
    const itemTableColumn = ["Item Name", "Adjustment", "Variant", "Quantity"];
    const itemTableRows = [];

    const formatVariantName = (variant) => {
        return variant ? `${variant.variant_set_name} - ${variant.variant_name}` : "-";
    };

    entry_items.forEach(item => {
        const itemData = [
            item.item_name,
            item.transform,
            formatVariantName(item.selectedVariant),
            item.qty
        ];
        itemTableRows.push(itemData);
    });

    doc.setFontSize(16);
    doc.setFont(undefined, 'bold');
    doc.text("STOCK ENTRY", 105, 20, null, null, 'center');

    doc.autoTable({
        body: detailRows,
        startY: 30,
        theme: 'plain',
        styles: { fontSize: 9, cellPadding: 2 },
        columnStyles: { 0: { fontStyle: 'bold' } },
        tableWidth: 'auto',
        margin: { right: 105 }
    });

    doc.autoTable({
        head: [itemTableColumn],
        body: itemTableRows,
        startY: doc.lastAutoTable.finalY + 10,
        theme: 'grid',
        headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
        styles: { fontSize: 9, cellPadding: 2 }
    });

    doc.save(`${entry_number}.pdf`);
};

export const generateStockEntryItemLabelsA4PDF = (stockEntryDetails) => {
    const {
        entry_items,
        entry_number,
        entry_branch_name,
        entry_date,
    } = stockEntryDetails;

    const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
    });

    entry_items.forEach((item, index) => {
        for (let qtyIndex = 1; qtyIndex <= item.qty; qtyIndex++) {
            const variantText = item.selectedVariant ? `${item.selectedVariant.variant_set_name} - ${item.selectedVariant.variant_name}` : "";
            const labelData = [
                ["Entry:", entry_number],
                ["Item:", item.item_name],
                ["Variant:", variantText],
                ["Branch:", entry_branch_name],
                ["Date:", entry_date],
                ["Quantity:", `${qtyIndex} of ${item.qty}`]
            ];

            doc.autoTable({
                body: labelData,
                theme: 'plain',
                styles: { fontSize: 22, cellPadding: 4 },
                columnStyles: { 0: { fontStyle: 'bold' } },
                margin: { top: 25, left: 20, right: 20 },
                tableWidth: 'wrap',
                showHead: false,
                showFoot: false,
            });

            if (!(qtyIndex === item.qty && index === entry_items.length - 1)) {
                doc.addPage("a4", "landscape");
            }
        }
    });

    doc.save(`A4_Labels_${entry_number}.pdf`);
};
