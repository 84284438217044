<template>
    <v-container>
    <v-row>
      <v-col>
        <!--Header-->
        <v-card-title>
          <v-btn @click="$router.go(-1)" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h3>Branch Stock Transfers</h3>
          
          <!--Button-->
          <v-btn
            elevation="2"
            color="#33cc99"
            dark
            class="ml-10" 
            @click="$router.push('/new-branch-stock-transfer')"
            >
            <v-icon left>mdi-plus</v-icon>
            New Stock Transfer
        </v-btn>
        </v-card-title>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
          <v-sheet elevation="4">
            <!--branch data table-->
            <template>
              <div>
                
                <v-data-table
                    :headers="tableHeaders"
                    :items="filteredItems"
                    :search="search"
                    show-expand
                    single-expand
                    item-key="transfer_id"
                    :items-per-page="500"
                >
                    <!-- Filters -->
                    <template v-slot:top>
                        <v-container>
                            <v-row class="p-3">
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        label="Search"
                                        outlined
                                        dense
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select
                                        v-model="fromBranchFilter"
                                        :items="branches"
                                        item-text="branch_name"
                                        item-value="branch_id"
                                        label="From Branch"
                                        clearable
                                        
                                        outlined
                                        dense
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select
                                        v-model="toBranchFilter"
                                        :items="branches"
                                        item-text="branch_name"
                                        item-value="branch_id"
                                        label="To Branch"
                                        clearable
                                        outlined
                                        dense
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select
                                        v-model="statusFilter"
                                        :items="statuses"
                                        label="Status"
                                        clearable
                                        outlined
                                        dense
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>

                    <!--Edit icon button-->
                    <template  v-slot:item.transfer_id="{ item }" >
                        <v-btn
                        :disabled="item.status === 'Completed' || item.status === 'Cancelled'"
                        :to="{
                            name: 'edit-branch-stock-transfer',
                            params: { transfer_id: item.transfer_id },
                        }"
                        color="#3d2cdd"
                        icon
                        small
                        ><v-icon small>mdi-pencil</v-icon></v-btn
                        >
                    </template>
                        <!--Line item expansion dropdown-->
                        <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="px-0">
                            <v-sheet elevation="0" class="py-3">
                            <v-container>
                                    <v-row>
                                        <v-col md="3" sm="3">
                                            <h4>Item name</h4>
                                        </v-col>
                                        <v-col md="3" sm="3">
                                            <h4>Variant</h4>
                                        </v-col>
                                        
                                        <v-col md="3" sm="3">
                                                <h4>QTY</h4>
                                        </v-col>
                                    </v-row>
                                    <div
                                        v-for="(e, index) in item.transfer_items"
                                        :key="e.item_id"
                                    >
                                    <v-row class="py-2">
                                        <v-col md="3" sm="3">
                                            {{e.item_name}}
                                        </v-col>
                                        <v-col md="3" sm="3">
                                            {{ e.selectedVariant ? formatProductVariant(e.selectedVariant) : "" }}
                                        </v-col>
                                        <v-col md="3" sm="3">
                                            {{e.qty}}
                                        </v-col>
                                        <v-col md="3" sm="3">
                                            
                                        </v-col>
                                    </v-row>
                                    <v-divider
                                        v-if="index < item.transfer_items.length -1"
                                        :key="index"
                                    ></v-divider>
                                    </div>
                            </v-container>

                            </v-sheet>
                        </td>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <v-chip :color="getStatusColor(item.status)" small>
                            {{ item.status }}
                        </v-chip>
                    </template>
                </v-data-table>
              </div>
            </template>
          </v-sheet>
        </v-col>
      </v-row>
  </v-container>
</template>
<script>
import db from "../../../components/firebaseInit";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
export default {
    name: "BranchStockTransfersDashboard",
    mixins: [mixin_UserRoles],
    data(){
        return {
            search: '',
            fromBranchFilter: null,
            toBranchFilter: null,
            statusFilter: null,
            statuses: ['Scheduled', 'In Transit', 'Completed', 'Cancelled'],
            stock_transfers: [],
            tableHeaders: [
              { text: 'Edit', sortable: true, value: 'transfer_id'},
              { text: 'Number', sortable: true, value: 'transfer_number'},
              { text: 'From', sortable: true, value: 'from_branch_name'},
              { text: 'To', sortable: true, value: 'to_branch_name'},
              { text: 'Status', sortable: true, value: 'status'},
              { text: 'Date Scheduled', sortable: true, value: 'date_scheduled'},
              { text: 'Date Received', sortable: true, value: 'date_received'},
            ],
            branches: [],
        }
    },
    computed: {
        filteredItems() {
        const searchLower = this.search.toLowerCase();
            return this.stock_transfers.filter((item) => {
                return (
                    (!this.fromBranchFilter ||
                        item.from_branch_name.toLowerCase() === this.fromBranchFilter.toLowerCase()) &&
                    (!this.toBranchFilter ||
                        item.to_branch_name.toLowerCase() === this.toBranchFilter.toLowerCase()) &&
                    (!this.statusFilter || item.status === this.statusFilter) &&
                    (item.transfer_number.toLowerCase().includes(searchLower) ||
                        item.from_branch_name.toLowerCase().includes(searchLower) ||
                        item.to_branch_name.toLowerCase().includes(searchLower))
                );
            });
        },
    },
    methods: {
        getBranchStockTransfers() {
            const docRef = db.collection("branch_stock_transfers").orderBy("transfer_number");

            docRef.onSnapshot((snapshot) => {
            this.stock_transfers = [];
            this.stock_transfers = snapshot.docs.map(doc => doc.data());
            });
        },
        formatProductVariant(variant) {
            const { variant_set_name, variant_name, variant_code } = variant;
            return `${variant_set_name} - ${variant_name} (${variant_code})`;
        },
        getStatusColor(status) {
            switch (status) {
                case 'Scheduled':
                    return 'grey lighten-2';
                case 'In Transit':
                    return 'yellow lighten-2';
                case 'Completed':
                    return 'green lighten-2';
                case 'Cancelled':
                    return 'red lighten-2';
                default:
                    return 'grey';
            }
        },
        async getBranches() {
            const docRef = db.collection('branches').orderBy('branch_name');

            const snapshot = await docRef.get();

            this.branches = snapshot.docs.map(x => x.data());
        }
    },
    created(){
      this.getBranchStockTransfers();
      this.getBranches();
    }
}
</script>
