<template>
    <v-container fluid>
        <v-row dense>
            <v-col lg="8" offset-lg="2">
                <v-card-title>
                    <v-btn to="/branch-stock-transfers" color="#ffffff" light elevation="0">
                        <v-icon left large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h3>New Branch Stock Transfer</h3>
                    <v-spacer />
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn  v-bind="attrs" v-on="on">
                                <v-icon left>mdi-download</v-icon>
                                Actions
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="generateBranchStockTransferPDF(transferDetails)">
                                <v-list-item-title>Download PDF</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="generateStockItemLabelsA4PDF(transferDetails)">
                                <v-list-item-title>Download A4 Item Labels</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="generateStockItemLabelsNimbotPDF(transferDetails)">
                                <v-list-item-title>Download Item Labels</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-card-title>
                <v-card>
                    <v-form @submit.prevent="submitStockTransfer" class="pa-3" ref="form" v-model="valid">
                        <v-container>
                            <v-row dense>
                                <!-- Transfer Number -->
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="transfer_number"
                                        label="Transfer #"
                                        readonly
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    ></v-text-field>
                                </v-col>

                                <!-- Status -->
                                <v-col cols="12" sm="6" md="6">
                                    <v-select
                                        v-model="status"
                                        ref="statusSelect"
                                        :items="statuses"
                                        :rules="[rules.required]"
                                        label="Status"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <!-- From Branch -->
                                <v-col cols="12" sm="6" md="6">
                                    <v-autocomplete
                                        v-model="from_branch_id"
                                        :items="branches"
                                        item-text="branch_name"
                                        item-value="branch_id"
                                        cache-items
                                        label="From Branch"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                        class="font-weight-bold"
                                        :menu-props="{ closeOnClick: true }"
                                        @change="getItems()"
                                    ></v-autocomplete>
                                </v-col>

                                <!-- To Branch -->
                                <v-col cols="12" sm="6" md="6">
                                    <v-autocomplete
                                        v-model="to_branch_id"
                                        :items="branches"
                                        item-text="branch_name"
                                        item-value="branch_id"
                                        :rules="[rules.required, rules.differentBranch]"
                                        cache-items
                                        label="To Branch"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                        class="font-weight-bold"
                                        :menu-props="{ closeOnClick: true }"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <!-- Date Scheduled -->
                                <v-col cols="12" sm="6" md="6">
                                    <v-menu
                                        ref="menuScheduled"
                                        v-model="menuScheduled"
                                        :close-on-content-click="true"
                                        transition="scale-transition"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="dateScheduled"
                                                label="Date Scheduled"
                                                append-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                dense
                                                outlined
                                                clearable
                                                color="#3d2cdd"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dateScheduled"
                                            no-title
                                            scrollable
                                            color="#3d2cdd"
                                            show-adjacent-months
                                            :allowed-dates="allowedDates"
                                            @input="menuScheduled = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <!-- Date Received -->
                                <v-col cols="12" sm="6" md="6">
                                    <v-menu
                                        ref="menuReceived"
                                        v-model="menuReceived"
                                        :close-on-content-click="true"
                                        transition="scale-transition"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="dateReceived"
                                                ref="dateReceivedField"
                                                label="Date Received"
                                                append-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                dense
                                                outlined
                                                clearable
                                                color="#3d2cdd"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dateReceived"
                                            no-title
                                            scrollable
                                            color="#3d2cdd"
                                            show-adjacent-months
                                            :allowed-dates="allowedDates"
                                            @input="menuReceived = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <!-- Line items -->
                            <hr>
                            <v-container v-if="itemsLoading" class="text-center">
                                <v-progress-circular indeterminate color="#3d2cdd"></v-progress-circular>
                            </v-container>
                            
                            <v-form v-else v-for="(item, index) in entry_items" :key="item.item_id" class="mt-5">
                                <v-row dense>
                                    <v-col :sm="item.variants && item.variants.length > 0 ? '3' : '5'" :md="item.variants && item.variants.length > 0 ? '3' : '5'">
                                        <v-autocomplete
                                            v-model="item.data"
                                            :items="items"
                                            item-text="item_name"
                                            :return-object="true"
                                            :rules="[rules.required]"
                                            placeholder="Choose an item..."
                                            cache-items
                                            required
                                            outlined
                                            dense
                                            color="#3d2cdd"
                                            @change="setItemData(item.data, index)"
                                            :disabled="isItemsEditDisabled"
                                        ></v-autocomplete>
                                    </v-col>
                                    <!-- Variants -->
                                    <v-col sm="3" md="3" v-if="item.hasVariants">
                                        <v-autocomplete
                                            v-model="item.selectedVariant"
                                            :items="item.variants"
                                            item-text="variant_name"
                                            return-object
                                            :rules="[rules.required]"
                                            cache-items
                                            :label="item.variant ? item.variant.variant_set_name : 'Variant'"
                                            outlined
                                            dense
                                            color="#3d2cdd"
                                            class="font-weight-bold"
                                            :menu-props="{ closeOnClick: true }"
                                            :disabled="isItemsEditDisabled"
                                        >
                                         <!-- Dropdown items -->
                                         <template v-slot:item="data">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ `${data.item.variant_set_name} - ${data.item.variant_name}` }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                    </v-autocomplete>
                                    </v-col>
                                    <!-- Quantity -->
                                    <v-col cols="12" sm="2" md="2">
                                        <v-text-field
                                            v-model.number="item.qty"
                                            type="number"
                                            label="Quantity"
                                            :rules="[rules.required, rules.positiveNumber]"
                                            outlined
                                            dense
                                            color="#3d2cdd"
                                            :disabled="isItemsEditDisabled"
                                        ></v-text-field>
                                    </v-col>
                                    <!-- Delete Button -->
                                    <v-col cols="12" sm="1" md="1">
                                        <v-btn v-if="index !== 0 && !isItemsEditDisabled" small icon color="red" @click="deleteEntryItem(index)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                            <!-- Add item button -->
                            <v-row dense v-if="!isItemsEditDisabled">
                                <v-col>
                                    <v-btn @click="addEntryItem" color="#33cc99" dark>
                                        <v-icon left>mdi-plus</v-icon>
                                        Add Item
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn to="/branch-stock-transfers" color="#ce2458" text x-large>
                            Cancel
                            <v-icon right>mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                            @click="submitStockTransfer"
                            color="#33cc99"
                            text x-large
                            :disabled="valid === false || (isEditDisabled && initialStatus === 'Completed')"
                            :loading="loading"
                            >
                            {{ $route.params.transfer_id ? 'Update' : 'Submit' }}
                            <v-icon right>mdi-check</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import { showSnackbar } from '../../../globalActions/index'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import { generateBranchStockTransferPDF, generateStockItemLabelsA4PDF, generateStockItemLabelsNimbotPDF } from '../data/branchTransferPDFs';

export default {
    name: "BranchStockTransferForm",
    mixins: [mixin_UserRoles],
    data() {
        return {
            valid: false,
            loading: false,
            itemsLoading: false,
            menuScheduled: false,
            menuReceived: false,
            transfer_id: null,
            transfer_number: null,
            from_branch_id: null,
            to_branch_id: null,
            dateScheduled: null,
            dateReceived: null,
            status: "Scheduled",
            initialStatus: null,
            statuses: ['Scheduled', 'In Transit', 'Completed', 'Cancelled'],
            entry_items: [
                {
                    item_id: null,
                    item_name: "",
                    qty: 0,
                    variants: [],
                    hasVariants: false,
                },
            ],
            branches: [],
            items: [],
            rules: {
                required: value => !!value || 'Required.',
                differentBranch: value => value !== this.from_branch_id || 'From Branch and To Branch cannot be the same.',
                positiveNumber: value => value > 0 || 'Quantity must be greater than 0.',
            },
        }
    },
    computed: {
        isEditDisabled() {
            return this.transfer_id && this.initialStatus === 'Completed';
        },
        isItemsEditDisabled() {
            return this.status !== 'Scheduled';
        },
        transferDetails() {
            return {
                entry_items: this.entry_items,
                transfer_number: this.transfer_number,
                from_branch_name: this.branches.find(branch => branch.branch_id === this.from_branch_id)?.branch_name || '',
                to_branch_name: this.branches.find(branch => branch.branch_id === this.to_branch_id)?.branch_name || '',
                date_scheduled: this.dateScheduled,
                date_received: this.dateReceived,
                status: this.status
            };
        }
    },
    methods: {
        generateBranchStockTransferPDF,
        generateStockItemLabelsA4PDF,
        generateStockItemLabelsNimbotPDF,
        async submitStockTransfer() {
            // Form validation
            if (!this.$refs.form.validate()) {
                return;
            }

            this.loading = true;

            try {

                const fromBranchName = this.branches.find(branch => branch.branch_id === this.from_branch_id)?.branch_name || '';
                const toBranchName = this.branches.find(branch => branch.branch_id === this.to_branch_id)?.branch_name || '';

                const transferData = {
                        transfer_number: this.transfer_number,
                        from_branch_id: this.from_branch_id,
                        from_branch_name: fromBranchName,
                        to_branch_id: this.to_branch_id,
                        to_branch_name: toBranchName,
                        date_scheduled: this.dateScheduled,
                        date_received: this.dateReceived,
                        status: this.status,
                        transfer_items: this.entry_items.map(item => ({
                            item_id: item.item_id,
                            item_name: item.item_name,
                            qty: item.qty,
                            selectedVariant: item.selectedVariant
                        }))
                    };

                if (this.$route.params.transfer_id) {
                    // Update existing transfer
                    const transferRef = db.collection('branch_stock_transfers')
                        .doc(this.$route.params.transfer_id);

                    // Update data
                    await transferRef.update(transferData);

                    // Update stock levels
                    await this.adjustStockLevels(transferData);

                    showSnackbar("Branch stock transfer updated successfully");


                } else {
                    // Create new transfer
                    const transferRef = db.collection('branch_stock_transfers').doc();

                    await transferRef.set({
                        transfer_id: transferRef.id,
                        ...transferData
                    });

                    // Increment 
                    const counterRef = db.collection("branch_stock_transfer_counter").doc("jAenZ3YLmygYOAAJDjxn");
                    const increment = firebase.firestore.FieldValue.increment(1);
                    counterRef.update({ transfer_count: increment });

                    // Update stock levels
                    await this.adjustStockLevels(transferData);

                    showSnackbar("New branch stock transfer added successfully");

                }
                // Update initialStatus to reflect the current status
                this.initialStatus = this.status;

                this.$router.push('/branch-stock-transfers');

            } catch (error) {
                console.error('Error creating branch stock transfer:', error);
            } finally {
                this.loading = false;
            }
        },
        //
        async adjustStockLevels(transferData) {
            const {
                from_branch_id,
                to_branch_id,
                status,
                transfer_items
            } = transferData;

            // Only adjust stock levels when status is set to "Completed"
            if (status !== "Completed") return;

            const batch = db.batch();

            // Reduce stock levels in "From branch"
            const fromBranchRef = db.collection("branches").doc(from_branch_id).collection("stock_levels");
            transfer_items.forEach((item) => {
                const itemRef = fromBranchRef.doc(item.item_id);
                if (item.hasVariants && item.selectedVariant) {
                    batch.update(itemRef, {
                        variants: firebase.firestore.FieldValue.arrayUnion({
                            variant_name: item.selectedVariant.variant_name,
                            variant_set_id: item.selectedVariant.variant_set_id,
                            stock_current: firebase.firestore.FieldValue.increment(-item.qty)
                        })
                    });
                } else {
                    batch.update(itemRef, {
                        stock_current: firebase.firestore.FieldValue.increment(-item.qty)
                    });
                }
            });

            // Increase stock levels in "To branch"
            const toBranchRef = db.collection("branches").doc(to_branch_id).collection("stock_levels");
            transfer_items.forEach((item) => {
                const itemRef = toBranchRef.doc(item.item_id);
                if (item.hasVariants && item.selectedVariant) {
                    batch.update(itemRef, {
                        variants: firebase.firestore.FieldValue.arrayUnion({
                            variant_name: item.selectedVariant.variant_name,
                            variant_set_id: item.selectedVariant.variant_set_id,
                            stock_current: firebase.firestore.FieldValue.increment(item.qty)
                        })
                    });
                } else {
                    batch.update(itemRef, {
                        stock_current: firebase.firestore.FieldValue.increment(item.qty)
                    });
                }
            });

            await batch.commit();
        },
        //
        addEntryItem() {
            this.entry_items.push({
                item_id: null,
                item_name: "",
                qty: 0,
                variants: [],
                hasVariants: false,
            });
        },
        deleteEntryItem(index) {
            this.entry_items.splice(index, 1);
        },
        getTransferNumber() {
            this.loading = true;

            const docRef = db.collection("branch_stock_transfer_counter").doc("jAenZ3YLmygYOAAJDjxn");

            docRef.onSnapshot((snapshot) => {
                const { transfer_count } = snapshot.data();
                // Format transfer_count with leading zeros
                const formattedCount = `${transfer_count + 1}`.padStart(4, '0');
                this.transfer_number = `BST-${formattedCount}`;
                this.loading = false;
            });
        },
        getBranches() {
            const docRef = db.collection("branches").orderBy("branch_name");

            docRef.onSnapshot((snapshot) => {
                this.branches = snapshot.docs.map(doc => doc.data());
            });
        },
        allowedDates(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return new Date(date) >= today;
        },
        //
        async getItems() {
            this.itemsLoading = true;
            
            if (!this.from_branch_id) return;

            const docRef = db.collection('branches')
                .doc(this.from_branch_id).collection('stock_levels').orderBy('item_name');

            const snapshot = await docRef.get();

            const stockLevels = snapshot.docs.map(doc => {
                    const data = doc.data();
                  
                    // Calculate stock_available for each variant
                    if (data.variants) {
                        data.hasVariants = true;
                        data.variants = data.variants.map(variant => {
                            variant.stock_available = variant.stock_current - variant.stock_allocated;
                            return variant;
                        });
                    } else {
                      data.stock_available = data.stock_current - data.stock_allocated;
                    }

                    return data;
                })
                this.items = stockLevels;
                this.itemsLoading = false;

        },
        //
        getMaxQty(item) {
            // Quantity limit is only enforced when the status is set to
            // "In Transit" or "Completed"
            if (this.status !== 'In Transit' && this.status !== 'Completed') {
                return Number.MAX_SAFE_INTEGER;
            }

            if (item.hasVariants && item.selectedVariant) {
                return item.selectedVariant.stock_available || 0;
            } else {
                return item.data ? item.data.stock_available : 0;
            }
        },
        setItemData(selectedItem, index) {
            if (selectedItem) {
                this.$set(this.entry_items[index], 'data', selectedItem);
                this.$set(this.entry_items[index], 'item_id', selectedItem.item_id);
                this.$set(this.entry_items[index], 'item_name', selectedItem.item_name);
                this.$set(this.entry_items[index], 'hasVariants', selectedItem.hasVariants);
                this.$set(this.entry_items[index], 'variants', selectedItem.variants);

                // Find the selected variant in the variants array
                const selectedVariant = selectedItem.variants.find(variant => variant.variant_id === selectedItem.selectedVariantId);
                this.$set(this.entry_items[index], 'selectedVariant', selectedVariant);

                // Reset quantity to 0 when item changes
                this.$set(this.entry_items[index], 'qty', 0);
            }
        },

        // Load data for edit form
        async loadTransferData(transferId) {
        try {
            const transferRef = db.collection('branch_stock_transfers').doc(transferId);
            const doc = await transferRef.get();
            if (doc.exists) {
                const transferData = doc.data();
                if (transferData.from_branch_id) {
                    await this.getItems();
                }
                this.populateForm(transferData);

                // Populate variants for each item
                await Promise.all(this.entry_items.map(async (item, index) => {
                    const itemRef = db.collection('items').doc(item.item_id);
                    const itemDoc = await itemRef.get();
                    if (itemDoc.exists) {
                        const itemData = itemDoc.data();
                        if (itemData.variants) {
                            this.$set(this.entry_items[index], 'variants', itemData.variants);
                            // Set the selected variant based on the variant_id stored in the transfer item
                            const selectedVariant = itemData.variants.find(variant => variant.variant_id === item.selectedVariantId);
                            this.$set(this.entry_items[index], 'selectedVariant', selectedVariant);
                        }
                    }
                }));
            } else {
                console.error('Transfer not found');
                this.$router.push('/branch-stock-transfers');
            }
        } catch (error) {
            console.error('Error fetching transfer data:', error);
        }
    },

    //
    async populateForm(data) {
        this.transfer_id = data.transfer_id;
        this.transfer_number = data.transfer_number;
        this.from_branch_id = data.from_branch_id;
        this.to_branch_id = data.to_branch_id;
        this.dateScheduled = data.date_scheduled;
        this.dateReceived = data.date_received;
        this.status = data.status;
        this.initialStatus = data.status;

        if (this.from_branch_id) {
            await this.getItems();
        }

        this.entry_items = data.transfer_items.map(item => {
            const matchingItem = this.items.find(i => i.item_id === item.item_id);
            if (matchingItem && matchingItem.variants) {
                // Set the variants for the item
                item.variants = matchingItem.variants;
                // Find and set the selected variant based on the variant stored in the transfer item
                const selectedVariant = matchingItem.variants.find(variant => 
                    variant.variant_name === item.selectedVariant.variant_name &&
                    variant.variant_set_id === item.selectedVariant.variant_set_id
                );
                item.selectedVariant = selectedVariant;
            }
            return {
                ...item,
                data: matchingItem || { item_id: item.item_id, item_name: item.item_name },
                hasVariants: matchingItem && matchingItem.variants ? true : false,
            };
        });
    }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            const transferId = to.params.transfer_id
            // `vm` is the instance of the incoming component
            if (transferId) {
                vm.loadTransferData(transferId);
            } else {
                vm.getTransferNumber();
            }

            vm.getBranches();
            vm.getItems();
        });
    },
}
</script>
